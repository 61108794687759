const BOOK_LIST = [
    {
        id: 'RICH DAD',
        path: '/reading-list/rich-dad',
        url: '',
        title: 'Rich Dad, Poor Dad',
        author: 'Robert Kiyosaki',
        category: 'Personal Finance',
        image: ['https://traperiadeklaus.es/uploads/libros/455847/180920232061_1_thumb_500.jpg', 'Book cover: Rich Dad, Poor Dad'],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'EMOTIONAL INTELLIGENCE',
        path: '/reading-list/emotional-intelligence',
        url: '',
        title: 'Emotional Intelligence',
        author: 'Daniel Goleman',
        category: 'Psychology',
        image: ['https://media.licdn.com/dms/image/D4E12AQHEvkIeQpTuGQ/article-cover_image-shrink_720_1280/0/1684554851940?e=2147483647&v=beta&t=zjnZwoTpEBtwAZTcURmKYughbcQw_SEDn2yw2W6heto', 'Book cover: Emotional Intelligence'],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'DOMINA EL MUNDO',
        path: '/reading-list/domina-el-mundo',
        url: '',
        title: 'Así se Domina el Mundo',
        author: 'Pedro Baños',
        category: 'Geo-Politics',
        image: ['https://cdn.wallapop.com/images/10420/fs/nm/__/c10420p955124723/i3598383108.jpg?pictureSize=W640', 'Book cover: Asi se Domina el Mundo'],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'MULTIPLE INTELLIGENCES',
        path: '/reading-list/multiple-intelligences',
        url: '',
        title: 'Multiple Intelligences Theory',
        author: 'Howard Gardner',
        category: 'Psychology',
        image: ['https://thebigbagteacher.com/wp-content/uploads/2017/10/bibliografia-inteliencias-multiples-5-e1508918164944-225x300.jpg', 'Book cover: Multiple Intelligences'],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'FIVE MINDS',
        path: '/reading-list/five-minds-future',
        url: '',
        title: 'Five Minds for the Future',
        author: 'Howard Gardner',
        category: 'Psychology',
        image: ['https://http2.mlstatic.com/D_NQ_NP_757591-MCO70746778589_072023-O.webp', 'Book cover: Five Minds for the Future'],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'MONK FERRARI',
        path: '/reading-list/monk-sold-ferrari',
        url: '',
        title: 'The Monk Who Sold His Ferrari',
        author: 'Robin Sharma',
        category: 'Spirituality',
        image: ['https://images.milanuncios.com/api/v1/ma-ad-media-pro/images/ebf2e1e4-d292-443f-bcac-8a28cb4c04c4?rule=hw396_70', 'Book cover: The monk who sold his Ferrari'],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'MAN THINK',
        path: '/reading-list/man-thinketh',
        url: '',
        title: 'As a Man Thinketh',
        author: 'James Allen',
        category: 'Logotherapy',
        image: ['https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRhS_5w-vksLQmvhYbZ3cja6OSEMjoq0ighg&usqp=CAU', 'Book cover: As A Man Thinketh'],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'SEARCHING MEANING',
        path: '/reading-list/man-searching-meaning',
        url: '',
        title: "Man's Search for Meaning",
        author: 'Viktor Frankl',
        category: 'Logotherapy',
        image: ['https://www.gertrudeandalice.com.au/wp-content/uploads/2021/02/5D26D582-912D-46E7-91C4-EA3306612905-2.jpg', "Book cover: Man's Search for Meaning"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'STEVE JOBS',
        path: '/reading-list/steve-jobs-biography',
        url: '',
        title: "Steve Jobs Biography",
        author: 'Walter Isaacson',
        category: 'Biography',
        image: ['https://di2ponv0v5otw.cloudfront.net/posts/2022/07/25/62df4e00dff94debfea42ac5/m_62df4e00dff94debfea42ac6.jpg', "Book cover: Steve Jobs Biography"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'MANDELA',
        path: '/reading-list/long-walk-freedom',
        url: '',
        title: "Long Walk to Freedom",
        author: 'Nelson Mandela',
        category: 'Autobiography',
        image: ['https://cdn.wallapop.com/images/10420/fs/fv/__/c10420p954762129/i3596724043.jpg?pictureSize=W640', "Book cover: Long walk to freedom"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'GROW RICH',
        path: '/reading-list/think-grow-rich',
        url: '',
        title: "Think and Grow Rich",
        author: 'Napoleon Hill',
        category: 'Mindset',
        image: ['https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFehMi8sdT_gKkefky-xnpCl7_ZLUlDhfRpQ&usqp=CAU', "Book cover: Think and grow rich"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'AGREEMENTS',
        path: '/reading-list/four-agreements',
        url: '',
        title: "The Four Agreements",
        author: 'Miguel Angel Ruiz Macías',
        category: 'Spirituality',
        image: ['https://target.scene7.com/is/image/Target/GUEST_59264c99-3743-4e81-8001-d2a26b25fe9b', "Book cover: The four agreements"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'NAPO LADRONES',
        path: '/reading-list/napoleon-of-crime',
        url: '',
        title: "The Napoleon of Crime",
        author: 'Ben Macintyre',
        category: 'Biography',
        image: ['https://d3525k1ryd2155.cloudfront.net/h/127/950/1454950127.0.x.jpg', "Book cover: The Napoleon of Crime"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'EGO ENEMY',
        path: '/reading-list/ego-enemy',
        url: '',
        title: "Ego Is The Enemy",
        author: 'Ryan Holiday',
        category: 'Mindset',
        image: ['https://store.dailystoic.com/cdn/shop/products/Ego1_900x.png?v=1603311137', "Book cover: Ego is the enemy"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: '48 POWER',
        path: '/reading-list/48-laws-power',
        url: '',
        title: "The 48 Laws of Power",
        author: 'Robert Greene',
        category: 'Leadership',
        image: ['https://cdn.wallapop.com/images/10420/fr/r6/__/c10420p953610452/i3590210406.jpg?pictureSize=W320', "Book cover: The 48 laws of power"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'BREATH',
        path: '/reading-list/and-breath',
        url: '',
        title: "And Breath",
        author: 'Rebecca Dennis',
        category: 'Breathwork',
        image: ['https://images.squarespace-cdn.com/content/v1/59ccecae46c3c43c9617aeaf/1508624963427-ZI290YWUO5NDD8A5D5SN/IMG_2245.JPG', "Book cover: And breath"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'SPRINT',
        path: '/reading-list/sprint',
        url: '',
        title: "Sprint",
        author: 'Jake Napp',
        category: 'Design Process',
        image: ['https://images.pangobooks.com/images/1eb9e3ea-a595-4db9-bfff-5152fd9f1df0?width=800&quality=85&crop=1%3A1', "Book cover: Sprint"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'DO',
        path: '/reading-list/pocket-full-of-do',
        url: '',
        title: "Pocket Full of Do",
        author: 'Chris Do',
        category: 'Leadership',
        image: ['https://media.licdn.com/dms/image/C4E22AQH9G2iDFgpD-A/feedshare-shrink_800/0/1624482757280?e=2147483647&v=beta&t=0DnRyfOMrZGfMQ1jsbd1pY9nxuhT5HtQVs5SwCHXo08', "Book cover: Pocket full of Do"],
        sinopsis: 'This book is a compilation of great advice and reflexions made by Chris on different areas of life -including design, obviously.',
        interest: 'I heard about if in many of his videos, even before the pre-launch. What got me interested was that is was his book haha! Besides he talked about some of the chapters on his videos and they seem so insightful and uplifting.',
        takeAways: 'This is one of thouse books that I will probably read again in a few years, just to refresh some questions and ideas.',
        featTag: [false, '', '']
    },
    {
        id: 'TRADING DUMMIES',
        path: '/reading-list/day-trading-for-dummies',
        url: '',
        title: "Day Trading y Operativa Bursátil",
        author: 'Francisca Serrano',
        category: 'Trading',
        image: ['https://cdn.wallapop.com/images/10420/de/el/__/c10420p810255740/i2789967965.jpg?pictureSize=W640', "Book cover: Day Trading y Operativa Bursátil"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'MINDSET',
        path: '/reading-list/mindset',
        url: '',
        title: "Mindset",
        author: 'Carol S. Dweck',
        category: 'Psychology',
        image: ['https://pictures-kenya.jijistatic.com/50622352_NjIwLTYyMC1kMTY5MzE1YTU1.webp', "Book cover: Mindset"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'GIVE A FUCK',
        path: '/reading-list/subtle-art-not-giving-fuck',
        url: '',
        title: "The Subtle Art of Not Giving a F*ck",
        author: 'Mark Manson',
        category: 'Mindset',
        image: ['https://meghanarao24.files.wordpress.com/2018/09/img_2509.jpg?w=730', "Book cover: The subtle art of not giving a f*ck"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'COACH HABITS',
        path: '/reading-list/coach-habits',
        url: '',
        title: "The Coaching Habits",
        author: 'Michael Bungay',
        category: 'Leadership',
        image: ['https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5s3ijUeM-DWD_uyOCH3U_6pcRbCTB6wAUSaZ69OvIA_aNsPc7k6Vvmh1rG2O9SYkaBGU&usqp=CAU', "Book cover: The coaching habits"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'THINK FAST',
        path: '/reading-list/thinking-fast-slow',
        url: '',
        title: "Thinking, Fast and Slow",
        author: 'Daniel Kahneman',
        category: 'Psychology',
        image: ['https://images.squarespace-cdn.com/content/v1/552d143de4b09906febc898c/1442964121154-09Y7AMTV8ZH1JP0XA8W8/image-asset.jpeg', "Book cover: Thinking, fast and slow"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'BRAND GAP',
        path: '/reading-list/brand-gap',
        url: '',
        title: "The Brand Gap",
        author: 'Marty Neumeier',
        category: 'Branding',
        image: ['https://static.wixstatic.com/media/2acb5c_2bf3403d0f954d86a8e20db5cbf38dbf~mv2.jpg/v1/fill/w_1000,h_600,al_c,q_85,usm_0.66_1.00_0.01/2acb5c_2bf3403d0f954d86a8e20db5cbf38dbf~mv2.jpg', "Book cover: The brand gap"],
        sinopsis: '',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'BUDDHA BADASS',
        path: '/reading-list/buddha-and-badass',
        url: '',
        title: "The Buddha and The Badass",
        author: 'Vishen Lakhiani',
        category: 'Leadership',
        image: ['https://static-01.daraz.com.bd/p/134f68ff016a8c62ef47bbd2d35e1cb2.jpg_750x750.jpg_.webp', "Book cover: The buddha and the badass"],
        sinopsis: 'This book makes a gratifying exploration of the intersection between business and spirituality, encouraging to mix those babies up for a better, more fulfilling life -personally and professionally. For me, it also describes leadership from a very human and empathetic perspective.',
        interest: "I got it by serendipity. I was after the Code of the Extraordinary Mind (Vishen's previous book) and they didn't have it, so I got offered 'the new one'. The sinopsis online stated: ideas for integrating spiritual values into our businesses and using them as foundations for strategies and leadership. Sold.",
        takeAways: "A few things that stuck with me: His take on breaking out of bullshit rules that are limiting our growth (Brules), how fenomenal Mindvalley got to be thanks to all these mindful ideas (he gives tons of examples), and the meaning behind the word Blisscipline (a fusion of discipline and bliss) -which he clearly stole from me hahaha. I will tell you why in a blog post, soon.",
        featTag: [false, '', '']
    },
    {
        id: 'ALMANACK',
        path: '/reading-list/almanack-naval-ravikant',
        url: '',
        title: "The Almanack of Naval Ravikant",
        author: 'Naval Ravikant',
        category: 'Mindset',
        image: ['https://miro.medium.com/v2/resize:fit:1400/1*4xneZfQMhWQtvUDscH90iQ.jpeg', "Book cover: The almanack of naval ravikant"],
        sinopsis: 'No summary here yet :) I will post some thought soon.',
        interest: '',
        takeAways: '',
        featTag: [false, '', '']
    },
    {
        id: 'LEAN STARTUP',
        path: '/reading-list/lean-startup',
        url: '',
        title: "The Lean Startup",
        author: 'Eric Ries',
        category: 'Business',
        image: ['https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxLNFRhmPXVXdFgZCPIHI_caiNbIFIA3IeEw&usqp=CAU', "Book cover: The lean startup"],
        sinopsis: 'This book outlines a methodology for developing succesful businesses and products with a systematic -and scientific- approach in times rapid innovation. For me it was about getting the fuck out of perfectionism and start building shit -haha.',
        interest: 'I first hear about it years ago, during a Project Manager course, but did not stick. Some time later, I saw it again in an UpFlip video about it (the one with Paul Akers) and I was hooked. The concept of "validated learning" and the Build-Measure-Learn feedback loop continues cycle was key.',
        takeAways: "However, I might be falling short here. There's way more useful ideas in there (like his deep take on pivoting or his approach to customer feedback) all explain in such a detail that you can resist to go start a BML loop right away -I know I did ;)",
        featTag: [false, '', '']
    },
    {
        id: 'LEAN PRODUCT',
        path: '/reading-list/lean-product',
        url: '',
        title: "The Lean Product Playbook",
        author: 'Dan Olsen',
        category: 'Product Design',
        image: ['https://media.karousell.com/media/photos/products/2021/5/17/the_lean_product_playbook_how__1621236965_a242aa7c.jpg', "Book cover: The lean product playbook"],
        sinopsis: 'This books is about how to effectively design successful products using Lean concepts. It revolves around software but the ideas are easily transferable to other industries.',
        interest: 'I hear about it during a Design Sprint Workshops course I took on 2019. I was automatically drawn by the Product-Market-Fit framework that Dan used to explain how -and why- to optimize product design within startup environments. I thought "Wow, that must be very useful". Spoiler alert: It definitely was.',
        takeAways: 'That pyramid was brilliant and I refer to it in every project. The book is also a step-by-step guide of the entire process Dan put together for optimal Lean design and explains in detail what to do in each step -and why it works. And the final chapter about measuring key metrics is pure GOLD. Worth every page.',
        featTag: [false, '', '']
    },
    {
        id: 'TOOLS TITANS',
        path: '/reading-list/tools-titans',
        url: '',
        title: "Tools of Titans",
        author: 'Tim Ferris',
        category: 'Productivity',
        image: ['https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJ7RO20qgpKMj6hi_mg7iCt4ck21dHsxA8iw&usqp=CAU', "Book cover: Tools of titans"],
        sinopsis: 'I recently started reading this book. It was sitting in backlog for a long time and I was holding back on reading it because it felt quite long. I just got my lazy ass to pick it up and start reading. So far so good. The way Ferriss distills the wisdom of his guests into actionable advice and strategies for success had me very curious.',
        interest: 'Come back later for insights on the book once I finish it. See ya :)',
        takeAways: '',
        featTag: [true, 'yellow', 'reading now']
    },
    {
        id: 'BLUE OCEAN',
        path: '/reading-list/blue-ocean-strategy',
        url: '',
        title: "Blue Ocean Strategy",
        author: 'C. Kim & R. Mauborgne',
        category: 'Business',
        image: ['https://media.licdn.com/dms/image/D4D22AQELZZNjz-nk_Q/feedshare-shrink_800/0/1696557993318?e=2147483647&v=beta&t=W-zHv_X3vyeJbXJKWRQnSfBVuZzzM8Qny-rYlwvu7Mc', "Book cover: Blue Ocean Strategy"],
        sinopsis: 'This book is about doing a radical shift of focus, from endlessly fighting in saturated markets where competition will wear us out, to innovating and creating new markets where competition is irrelevant and we are free to own the room (or sea). Sounds great, right?',
        interest: "I heard about it years ago, when I was trying to join the Cirque du Soleil. I kept reading references over the years. But a few weeks ago, I watched Alex Hormozi made a quick summary and I thought it was about time to finally read the real thing. My goal: Get enlighten by this holy strategy that will give me the ability to find and navigate blue oceans. Mine was red enough already haha.",
        takeAways: "You will find great takeaways and fine tools -like the Strategy Canvas or the Four Actions Framework. But let me tell you what was key for me: Our businesses will never see blue oceans if we sit on our confirmation biases, stick to price competition, and hold on to industry assumptions. We have the responsability to challenge conventions, look creatively in other directions, synthetize concepts, understand our customers like never before, and innovate with the purpose of creating more value -not more cheap things. By the way, I loved how Cirque du Soleil made it to be in almost half of this book. That alone paid for the book haha 🎪.",
        featTag: [true, 'black', 'most recent']
    },
    {
        id: 'RICH BABYLON',
        path: '/reading-list/rich-babylon',
        url: '',
        title: "The Richest Man in Babylon",
        author: 'George S. Clason',
        category: 'Mindset',
        image: ['https://www.iwillteachyoutoberich.com/wp-content/uploads/2015/12/1kNDtRDE6mk78x3PGF6AQr9xDmEkQXNqK2o9aHo30MA.jpg', "Book cover: The Richest Man in Babylon"],
        sinopsis: 'This book teaches great lessons about finance through stories based on Babylon parables. Once the richest city in the world, where people had a wise perspective about money, work, and good fortune.',
        interest: "It came up, on and off, in many podcasts. Always referred to as one of the best book in finance. With real gems hidden in stories and advices told by wealthy men from Babylon. As with many other 'parables books', it got a bit flat form time to time. But c'mon, it's a classic from 1962, what did I expect 😏.",
        takeAways: "Let me try to sell you the book with this: Fortune is not for whiners and lazy-asses. You have to make it rain, and if you don't know how, go ask some honest person that have. Once you do, control your stupid ego and save a good part of it for the future. When you have enough, invest it wisely (in yourself and in business). When those investments bring you a little profit, guess what? Reinvest that shit too. These, along with honesty, hard-work, and patience will paid off. - Worst summary of a book ever 🤦🏻‍♂️. Just go read it and get smarter :)",
        featTag: [true, 'black', 'most recent']
    },
    {
        id: 'PMBA',
        path: '/reading-list/personal-mba',
        url: '',
        title: "The Personal MBA",
        author: 'Josh Kaufman',
        category: 'Business',
        image: ['https://media.karousell.com/media/photos/products/2023/5/1/the_personal_mba_1682950794_22d4d850_progressive.jpg', "Book cover: The Personal MBA"],
        sinopsis: "Come back later for insights on the book once I finish it. See ya :)",
        interest: "",
        takeAways: "",
        featTag: [true, 'yellow', 'reading now']
    },
]

export default BOOK_LIST;