const WEB_PROJECTS = [
    {
        id: 'THC',
        path: '/web-design-portfolio/hiking-club',
        url: 'https://napetico.github.io/the-hiking-club/',
        client: {
            name: 'The Hiking Club',
            image: ['https://napetico.github.io/the-hiking-club/media/club-logo.png', 'The Hiking Club logo'],
            bio: 'THC is a local hiking club that organizes weekend outdoors excursions and climbing activities for adults, kids, pros and amaters. Their mission is to bring the community closer to local mountains and beautiful trails, promoting an active livestyle and the love for nature. I made it all up to build the site, but if you know a club that can use a nice website send them my way :)',
        },
        category: 'Sports Organization',
        mainImages: {
            card: ['/images/web/napo-thc-web-image.png', 'Website mockups'],
            poster: ['/images/web/napo-thc-poster.png', 'Website mockups'],
        },
        cardSnippet: 'Website for',
        homeSnippet: '',
        homeTitle: '',
        featTag: [true, 'black', 'made with code'],
        heroCard: ['Title', 'Description'],
        page: {
            sectionTitle: 'An informative hub for the community.',
            sectionInfo: 'Where sports, nature, and good vibes collide.',
            goal: 'In my head, THC needed a website asap. The number of people asking and signing up for their activities was growing fast and they needed a better site to handle all these interactions and information. Their three priorities were: a simple design, being able to publish the schedule and details of excursions, and having a place to share stories and photos of the adventures. Of course, I made this up too -haha.',
            plan: 'The plan was to make a simple home page, easy to navigate from, specially to the hikes calendar and blog. Users can sign up to any activity through a quick form that also gave them plenty of information about the hike. Lastly, I gave it a nice social vibe using engaging content (like tons of photos and nice articles) and other useful interactions (like sharing, seeing who has signed up, and leaving some "likes").',
            outcome: 'THC website looks great. I only built the home page to show how the project would look like, but you will see that I included all the features that I had in mind for the rest of the site: cards to subscribe to events, access to blog posts, sharing buttons, and more.',
        },
        introImages: [
            {image: ['/images/web/napo-thc-moodboard.png', 'Pinterest moodboard'], tag: 'Gathering inspiration for a moodboard'},
            {image: ['/images/web/napo-thc-wireframes.svg', 'Website wireframes'], tag: 'Wireframes for the hikes calendar cards '},
            {image: ['/images/web/napo-thc-mockups.png', 'Website mockups'], tag: 'Final visual direction for THC'},
        ],
        projectImages: [
            {image: ['/images/web/napo-thc-portfolio-1.png', 'Website mockup'], tag: 'Hero section at Home page'},
            {image: ['/images/web/napo-thc-portfolio-2.png', 'Website mockup'], tag: 'Cards for Events calendar'},
            {image: ['/images/web/napo-thc-portfolio-3.png', 'Website mockup'], tag: 'Cards for Blog posts'},
            {image: ['/images/web/napo-thc-portfolio-4.png', 'Code screenshot'], tag: 'Part of the HTML and CSS code'},
        ],
    },
    {
        id: 'OL',
        path: '/web-design-portfolio/olearn',
        url: 'https://olearn.webflow.io/',
        client: {
            name: 'Olearn.eu',
            image: ['https://uploads-ssl.webflow.com/645ac9a45ae22711e88cbad7/645b93948e93274d69a07db6_olearn-logo-circle-instagram.svg', 'Olearn logo'],
            bio: 'Olearn.eu is a quirky Spain-based Spanish school creating smart, fun, and all-included study abroad experiences and summer camps for young adverturers looking to have the time of their lives while learning the most from a beautiful culture.',
        },
        category: 'Abroad Spanish School',
        mainImages: {
            card: ['/images/web/napo-ol-web-image.png', 'Website mockups'],
            poster: ['/images/web/napo-ol-web-poster.png', 'Website mockups'],
        },
        cardSnippet: 'Website for',
        homeSnippet: '',
        homeTitle: '',
        featTag: [false, '', ''],
        heroCard: ['Title', 'Description'],
        page: {
            sectionTitle: 'Two opposite styles mixed in one web.',
            sectionInfo: 'Them: "It has to be fun and crazy, but organized and serious, a little bit messy but clean and uncluttered..." - Me: "ehm, okey..."',
            goal: 'The Olearn team wanted a website as bombastic as the personality they inyected into their Spanish school. For them, learning, traveling, and having fun like never before were all in the same pot, and their abroad programs were the proof. They had four things very clear. The site has to be vibrant and fun. Students must be able to book, pay, and get in touch. Their Instagram posts needed a place on the home page. And, they wanted to sell some merch.',
            plan: 'After some research and design explorations we decided for a great, very on-brand style for the site. We crafted cool copys that sounded as vibrant as the design. We used a simple website structure (home, about, products... the usual). Each program had its own page, easily accesible form the navigation. We pivoted on the IG post idea haha. Finally, from the footer, students had access the blog, store (go merch!), legal info, contact info, and more features.',
            outcome: 'The website for Olearn was a match for their extravaganzza. They quickly filled it up with blog posts, travel photos, students stories, cool study-abroad program offers, and more fun content. ',
        },
        introImages: [
            {image: ['/images/web/napo-ol-sketches.svg', 'Logo sketches'], tag: 'Strategy and on-brand ideation'},
            {image: ['/images/web/napo-ol-colors.svg', 'Color pallet examples'], tag: 'Color pallet to lead the design'},
            {image: ['/images/web/napo-ol-mockups.png', 'Website mockups'], tag: 'Visual direction for the website'},
        ],
        projectImages: [
            {image: ['/images/web/napo-ol-final-4.png', 'Website mockup'], tag: 'Section talking about one of their values'},
            {image: ['/images/web/napo-ol-final-3.png', 'Website mockup'], tag: 'Fun section about a benefit and colorful CTA stripe'},
            {image: ['/images/web/napo-ol-final-2.png', 'Contact form mockup'], tag: 'Form to download a PDF student guide for the trip'},
            {image: ['/images/web/napo-ol-final-1.png', 'Priceing cards mockup'], tag: 'Cards with program prices and some features'},
            {image: ['/images/web/napo-ol-final-6.png', 'Instagram posts mockups'], tag: 'Desing ideas for IG posts strategy'},
            {image: ['/images/web/napo-ol-final-5.png', 'Online store product cards'], tag: 'Cards for the merch online shop page'},
        ],
    },
    {
        id: 'BL',
        path: '/web-design-portfolio/biting-lemons',
        url: 'https://bitinglemons.webflow.io/',
        client: {
            name: 'Biting Lemons',
            image: ['/images/TEST-bl-client-image.png', 'Biting Lemons logo'],
            bio: 'Biting Lemons is an online magazine with the mission of producing meaningful, uncensored, and kind-hearted content around hard-to-talk topics, all through the intimate stories of peculiar people and their singualr life experiences.',
        },
        category: 'Films & Content Producer',
        mainImages: {
            card: ['/images/web/napo-bl-web-image.png', 'Website mockups'],
            poster: ['/images/web/napo-bl-web-poster.png', 'Website mockups'],
        },
        cardSnippet: 'Website + CMS for',
        homeSnippet: '',
        homeTitle: '',
        featTag: [false, '', ''],
        heroCard: ['Title', 'Description'],
        page: {
            sectionTitle: 'A kind place to give light to real stories.',
            sectionInfo: 'Where our tribe can watch, read, or listen to insightful content.',
            goal: "Biting Lemons needed a vibrant and well-structured website in which they could organize and share all their content. They wanted their audience to feel and breath their brand and also enjoy a  super easy access to BL's articles, videos and podcasts. Their three priorities were: a minimalist and on-brand aesthetic, a simple and intuitive user experience, and a nice back-end that allows them to manage and scale their multi-format content without headaches.",
            plan: 'My plan was to build a site with the content as main protagonist. Categorizing it based on the way we consume it (watch, read, listen) and share it in category galleries with well-design cards easy to navigate. Together, we decided to give special attention to the interactions -such as commenting, sharing, or subscribing to the newsletter. Finally, I built them a great CMS, which I structured following the flow of their content creation process, so they can manage and scale their content base within the site without loosing their nerves.',
            outcome: "The website scored a big 10 for Biting Lemons. The design was on-point and on-brand and the UX was simple and very intuitive. The CMS turned out to be the best thing about the project; easy to manage, adapted to their production processes, and scalable-ready. It just need some awesome content and this will be a wrap.",
        },
        introImages: [
            {image: ['/images/web/napo-bl-ideation.png', 'Remote ideation workshop sketches'], tag: 'Strategy session with BL team'},
            {image: ['/images/web/napo-bl-wireframes.png', 'Website wireframes in Figma'], tag: 'Sitemaps and wireframes ideas'},
            {image: ['/images/web/napo-bl-mockups.png', 'Website visual design explorations'], tag: 'Visual direction and UX examples'},
        ],
        projectImages: [
            {image: ['/images/web/napo-bl-project-1.png', 'Web design screenshot'], tag: 'Colorful Hero section for the home page'},
            {image: ['/images/web/napo-bl-project-2.png', 'Web design for media cards'], tag: 'Animated cards to show content info '},
            {image: ['/images/web/napo-bl-project-3.png', 'Web pages mockups'], tag: 'Gallery pages to show the content of each category'},
            {image: ['/images/web/napo-bl-project-4.png', 'Web design mockup'], tag: 'Jazzy Newsletter section and CTA stripes'},
            {image: ['/images/web/napo-bl-project-5.png', 'Web design mockup'], tag: 'Content page template for all the formats'},
            {image: ['/images/web/napo-bl-project-6.png', 'Webflow CMS screenshot'], tag: 'Screenshoot of the CMS dashboard'},
        ],
    },
    {
        id: 'NK',
        path: '/web-design-portfolio/nukka',
        url: 'https://thenukka.com/',
        client: {
            name: 'The Nukka Co.',
            image: ['https://i.pinimg.com/1200x/dc/ec/4f/dcec4ff83bbdd60223e0839e57e7dd98.jpg', 'The Nukka Coompany logo'],
            bio: 'The Nukka Company is a Swedish startup making bio-degradable bamboo razors with great design and performance. Their mission is to contribute to cleaner oceans and better environmental values.',
        },
        category: 'Startup - Ecoshaving',
        mainImages: {
            card: ['/images/web/napo-nk-web-image.png', 'Website mockups'],
            poster: ['/images/web/napo-nk-poster.png', 'Website mockup'],
        },
        cardSnippet: 'Wireframes for',
        homeSnippet: '',
        homeTitle: '',
        featTag: [false, '', ''],
        heroCard: ['Title', 'Description'],
        page: {
            sectionTitle: 'Searching for the right visual design.',
            sectionInfo: 'A wide exploration for an on-brand and impactful website.',
            goal: 'The Nukka team was looking for the right look & feel for their new site. They had a pre-launch landing page and were ready to move up to a full e-commerce site. My job was to explore lots of designs based on their brand so their team could pick one to build.',
            plan: 'This project was very straight forward. After giving a deep look to their brand guidelines I started my creative process: gathering lots of styles and inspirations into a mood boards, creating wireframes and bold copywriting, and finally generating dozens of mockups.',
            outcome: 'More than 50 different styles were generated. The team at Nukka loved the wide exploration, chose their favorites, mixed them, and we got to a couple of final great designs for their site.',
        },
        introImages: [
            {image: ['/images/web/napo-nk-logos.svg', 'Nukka brand logos'], tag: 'Nukka logos & color exploration'},
            {image: ['/images/web/napo-nk-target.svg', 'Target customer brief'], tag: 'Target customer strategy session'},
            {image: ['/images/web/napo-nk-moodboard.png', 'Moodboard'], tag: 'One of the many moodboards I explored'},
        ],
        projectImages: [
            {image: ['/images/web/napo-nk-final-1.png', 'Website hero section mockup'], tag: 'Design exploration #013 - Clean aesthetics'},
            {image: ['/images/web/napo-nk-final-2.png', 'Website hero section mockup'], tag: 'Design exploration #021 - "Lots of nature" design'},
            {image: ['/images/web/napo-nk-final-3.png', 'Website hero section mockup'], tag: 'Design exploration #007 - "Start with benefits" idea'},
            {image: ['/images/web/napo-nk-final-4.png', 'Website home page mockup'], tag: 'Design exploration #042 - "For the sport fans" theme'},
            {image: ['/images/web/napo-nk-final-5.png', 'Website home page mockup'], tag: 'Design exploration #035 - "Razors & smiley faces" mood'},
            {image: ['/images/web/napo-nk-final-6.png', 'Website home page mockup'], tag: 'Design exploration #53 - "Ocean, ocean, ocean" message'},
        ],
    },
    {
        id: 'LH',
        path: '/web-design-portfolio/lahdo-renovations',
        url: 'https://lahdo.webflow.io/',
        client: {
            name: 'Lahdo Renovations',
            image: ['https://media.licdn.com/dms/image/D4D03AQHez5WhAOswfQ/profile-displayphoto-shrink_800_800/0/1669851094690?e=2147483647&v=beta&t=FG0k0UyTs2vF97jGwCHdDc6XPs4Pu8022h_e0hY_O6k', 'Lahdo Renovations logo'],
            bio: 'Lahdo.co is a home renovation company building better living spaces for second-home owners in Spain with efficiency and great design, while leveraging the power of technology, craftmanship, and sustainability to make the process easy and remotely.',
        },
        category: 'Home Renovations',
        mainImages: {
            card: ['/images/web/napo-lh-web-image.png', 'Website mockups'],
            poster: ['/images/web/napo-lh-web-poster.png', ''],
        },
        cardSnippet: 'Website for',
        homeSnippet: '',
        homeTitle: '',
        featTag: [false, '', ''],
        heroCard: ['Title', 'Description'],
        page: {
            sectionTitle: 'A beautiful portfolio of exquisite homes.',
            sectionInfo: 'Browse and get inspired to build the place of your dreams.',
            goal: 'Lahdo.co needed to re-design their old website. The business was scaling and they wanted to embrace a new focus: designing and building fine spaces remotely. The new site had to convey that message. Their priorities: A nice portfolio with details of each project, a clear break-down of their process and benefits, and the use elegant animations.',
            plan: 'The strategy sessions gave us a clear structure and on-brand visual direction, also a clear story to explain their processes and benefits. I built a CMS and a template page to give each project a unique page. This made it easy to add and edit projects. Finally, I sprinkled some sutil animations (like the appear-on-scroll navbar or the navigation menu thet pops from the side) that gave the page a graceful look.',
            outcome: 'Lahdo.co launched a renovated site. They love the CMS to manage the information for every project, the simple way that the process was explained (including Q&As), and how sutil the animations were.',
        },
        introImages: [
            {image: ['/images/web/napo-lh-logos.svg', 'Brand identity guidelines'], tag: 'Brand identity and color exploration'},
            {image: ['/images/web/napo-lh-wireframe.svg', 'Website wireframes'], tag: 'Wireframe idea for the hero section'},
            {image: ['/images/web/napo-lh-mockup.png', 'Website mockups'], tag: 'Visual direction moodboards'},
        ],
        projectImages: [
            {image: ['/images/web/napo-lh-final-1.png', 'Website mockup'], tag: 'Side navigation menu opening on hover'},
            {image: ['/images/web/napo-lh-final-3.png', 'Website mockup'], tag: 'Horizontal scroll for services cards'},
            {image: ['/images/web/napo-lh-final-2.png', 'Website mockup'], tag: 'Consistent design throughout pages'},
            {image: ['/images/web/napo-lh-final-4.png', 'Website mockup'], tag: 'Succinct project page highlighting key details'},
            {image: ['/images/web/napo-lh-final-5.png', 'Website mockup'], tag: 'Footer section with final CTA and sitemap'},
            {image: ['/images/web/napo-lh-final-6.png', 'Website mobile mockup'], tag: 'Easy to navigate responsive design (mobile)'},
        ],
    },
    {
        id: 'SV',
        path: '/web-design-portfolio/supervillanos',
        url: 'https://supervillanos.webflow.io/',
        client: {
            name: 'Super-villanos.co',
            image: ['/images/napo-sv-logo.png', 'Supervillanos.co logo'],
            bio: 'Supervillanos.co is a creative studio helping businesses tackle high-level challenges through an extraordinary branding, powerful strategies, and the continuous optimization of processes.',
        },
        category: 'Business & Creative Studio',
        mainImages: {
            card: ['/images/web/napo-sv-web-image.png', 'Website mockups'],
            poster: ['/images/web/napo-sv-web-poster.png', 'Website mockup'],
        },
        cardSnippet: 'Website + CMS for',
        homeSnippet: 'Web for',
        homeTitle: "Injecting the power of brand into an killer website",
        featTag: [true, 'yellow', 'latest website'],
        heroCard: ['Supervillanos.co', 'Creative Studio | Webflow site + CMS'],
        page: {
            sectionTitle: 'A powerful site for building top brands.',
            sectionInfo: 'Remarkable brands that have the heck of an impact on your business -of course, it has to be one made by Supervillanos 😉.',
            goal: 'This site was the epitome of overthinking. As co-founder, I wanted it to be perfect and went off idealizing expectations for its design and features -shoot for moon, right?. We urgently needed some strategy sessions to prioritize ideas and build a clear sandbox to be creative in. All we knew for sure was that we want it to be elegantly designed, gracefully animated, as simple as it could be, and very useful for any visitor -client, curious, entrepreneur, our moms... anybody.',
            plan: "After some hardcore strategy sessions, we came up with a great design -based on the SV's awesome branding- and neat structure for the site -based on the main goal: driving sales!. We divided our main pages to go about who we are, what we do, how we do it, show previous work, and give away free resources and insights -last two were organized in a CMS. We planned for good color balance, great headlines, succinct text, and great imagery. And about the animations, I had an awesome idea for the main navigation -open the web to check it out.",
            outcome: "The website for Supervillanos.co is fantastic. The design is on-brand and the information is clear and useful. We look like pros, highly experienced, and with authority. This will drive lots of sales and interest, for sure. It's time to build some brands!",
        },
        introImages: [
            {image: ['/images/web/napo-sv-customer.svg', 'Target customer brief'], tag: 'Target customer strategy session'},
            {image: ['/images/web/napo-sv-moodboard.png', 'Design moodboard'], tag: 'Design exploration moodboard'},
            {image: ['/images/web/napo-sv-mockups.svg', 'Website hero section mockups'], tag: 'Design for three sections of the site'},
        ],
        projectImages: [
            {image: ['/images/web/napo-sv-final-3.png', 'Website mockup'], tag: 'Hero section for the Services page'},
            {image: ['/images/web/napo-sv-final-2.png', 'Website mockup'], tag: 'Opened full-screen navigation menu (animated)'},
            {image: ['/images/web/napo-sv-final-4.png', 'Website mockup'], tag: 'Cards to navigate to the two main services'},
            {image: ['/images/web/napo-sv-final-1.png', 'Website mockup'], tag: 'Provisional section with portfolio images and info'},
            {image: ['/images/web/napo-sv-final-5.png', 'Website mockup'], tag: 'Detailed process breakdown for each service'},
            {image: ['/images/web/napo-sv-final-6.png', 'Website mockup'], tag: 'Section with more details and a CTA'},
            {image: ['/images/web/napo-sv-final-7.png', 'Website mobile mockup'], tag: 'Mobile mockups for the contact page'},
            {image: ['/images/web/napo-sv-final-8.png', 'Website mobile mockup'], tag: 'Mobile mockups for the resources page'},
        ],
    }
]

export default WEB_PROJECTS;